'use client';

import AuthGuard from '@/src/components/AuthGuard';
import { ApiProvider } from '@/src/providers/Api';
import { TenantMeProvider } from '@/src/providers/Me';
import { AuthProvider } from '@descope/nextjs-sdk';
import { ReactNode } from 'react';

import useEnvironment from '../../providers/Environment';

export default function TenantLayout({ children }: { children: ReactNode }) {
  const { natoma, descope } = useEnvironment();
  const descopeProjectId = descope.projectId || '';

  return (
    <AuthProvider
      projectId={descopeProjectId}
      sessionTokenViaCookie
      baseUrl={`https://auth.${natoma.rootDomain}`}
    >
      <ApiProvider>
        <TenantMeProvider>
          <AuthGuard layout="tenant">{children}</AuthGuard>
        </TenantMeProvider>
      </ApiProvider>
    </AuthProvider>
  );
}
